.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.grid-item svg,
.grid-item img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .grid-item svg,
    .grid-item img {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }
}